import { useState, useEffect } from "react";
import { ethers, JsonRpcProvider, Contract } from "ethers";
import Gnatsabi from './Gnats.json';
import './eventspage.css';

const GnatContractAddress = "0xC3cdc31392B062c79A1307dD489079b28D8404e7";
const provider = new JsonRpcProvider("https://mainnet.infura.io/v3/375eb9f9f5a6474fbac271fd006da1e8");
const contract = new Contract(GnatContractAddress, Gnatsabi, provider);
const filterFrom = contract.filters.Transfer(null, null, null);

export default function Events() {

  const [tx, setTx] = useState([]);

  const handleTransferEvent = async (from, to, tokenId, txHash) => {
    let type;
    if (from === ethers.ZeroAddress) {
      type = "mint";
    } else {
      type = "transfer";
    }

    const transaction = await provider.getTransaction(txHash);
    const receipt = await provider.getTransactionReceipt(txHash);
    const block = await provider.getBlock(receipt.blockNumber);

    setTx((prev) => [
      {
        type,
        from,
        to: to.toString(),
        tokenId: tokenId.toString(),
        txHash,
        blockNumber: receipt.blockNumber,
        timestamp: new Date(block.timestamp * 1000).toLocaleString(),
        gasUsed: receipt.gasUsed.toString(),
        value: ethers.formatEther(transaction.value),
      },
      ...prev,
    ].slice(0, 10));
  };

  useEffect(() => {
    const fetchOldEvents = async () => {
      const currentBlockNumber = await provider.getBlockNumber();
      const items = await contract.queryFilter(filterFrom, 1, currentBlockNumber);
      const top10Items = Array.from(items).slice(-10);

      if (top10Items.length === 0) {
        console.log('No transfers found');
        return;
      }

      top10Items.forEach((item) => {
        const txHash = item.transactionHash;
        //console.log(`From: ${item.args[0]}, To: ${item.args[1]}, Value: ${item.args[2]}`);
        handleTransferEvent(item.args[0], item.args[1], item.args[2], txHash);
      });
    };

    contract.on("Transfer", handleTransferEvent);
    fetchOldEvents();

    return () => {
      contract.removeAllListeners("Transfer");
    };
  }, []);

  return (
    <div className="topdiv">
      <table className="tbl">
        <thead className="thead">
          <tr className="tblrow">
            <th className="status">Status</th>
            <th>From Address</th>
            <th>To Address</th>
            <th>TokenID</th>
            <th className="block-number">Block Number</th>
            <th className="timestamp">Timestamp</th>
            <th className="gas-used">Gas Used</th>
            <th className="value">Value</th>
            <th className="tx">Transaction Hash</th>
          </tr>
        </thead>
        <tbody>
          {tx.map((log, index) => (
            <tr className={`flex flex-row ${index % 2 === 0 ? 'row-even' : 'row-odd'}`} key={index}>
              <td className="text-xs w-16 mr-2">
                <a key={log.txHash} href={`https://etherscan.io/tx/${log.txHash}`} target="_blank" rel="noreferrer">
                  {log.type === "mint" && (
                    <span key={log.type} className="mr-2 bg-green-400 px-3">{log.type}</span>
                  )}
                  {log.type === "transfer" && (
                    <span key={log.type} className="mr-2 bg-yellow px-3">{log.type}</span>
                  )}
                </a>
              </td>
              <td className="text-xs w-48">
                <a key={log.from} href={`https://etherscan.io/address/${log.from}`} target="_blank" rel="noreferrer">
                  {log.from.slice(0, 6)}
                </a>
              </td>
              <td className="text-xs w-48">
                <a key={log.to} href={`https://etherscan.io/address/${log.to}`} target="_blank" rel="noreferrer">
                  {log.to.slice(0, 6)}
                </a>
              </td>
              <td className="text-xs w-32">
                <a key={log.tokenId} href={`https://etherscan.io/token/${GnatContractAddress}?a=${log.tokenId}`} target="_blank" rel="noreferrer">
                  #{log.tokenId}
                </a>
              </td>
              <td className="text-xs w-32 block-number">
                {log.blockNumber}
              </td>
              <td className="text-xs w-48 timestamp">
                {log.timestamp}
              </td>
              <td className="text-xs w-32 gas-used">
                {log.gasUsed}
              </td>
              <td className="text-xs w-32 value">
                {log.value} ETH
              </td>
              <td className="text-xs w-36 tx">
                <a key={log.txHash} href={`https://etherscan.io/tx/${log.txHash}`} target="_blank" rel="noreferrer">
                  {log.txHash.toString()}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
