
import { ChakraProvider } from '@chakra-ui/react';
import NavBar from './NavBar';
import MainText from './MainText';
import Footer from './footer';
import './App.css';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4'; // Import ReactGA

function Home() {

 // Track the pageview on component mount
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });
    }, []);

  return (
      <div className='overlay'>
        <div className="App">
          <ChakraProvider>
              <NavBar/>
              <MainText /> 
              <Footer></Footer>
          </ChakraProvider>
        </div>
        <div /> 
    </div>
  );
}
export default Home;


//--------------underconstruction home page--------------------------//

/*
import UnderConstruction from './underconstruction';
function Home(){
  return (
    <div className='overlay'>
      <UnderConstruction />
    </div>
  )
}
export default Home;
*/
