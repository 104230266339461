import { NavLink } from 'react-router-dom';
import './navbar.css'; 
//import { ConnectButton } from '@rainbow-me/rainbowkit';

const NavBar = () => {
    return (
        <div className="navbar">
            <div className="nav-links">
                <NavLink to='/about'><div className="nav-link-box">About</div></NavLink>
                <NavLink to='/roadmap'><div className="nav-link-box">Roadmap</div></NavLink>
                <NavLink to='/faq'><div className="nav-link-box">FAQ</div></NavLink>
                <NavLink to='/whygnats'><div className="nav-link-box">Why Gnats?</div></NavLink>
                <NavLink to='/mintingdetails'><div className="nav-link-box">Mint Details</div></NavLink>
                <NavLink to='/'><div className="nav-link-box">Events</div></NavLink>
              
            </div>
        </div>
    );
};

export default NavBar;
