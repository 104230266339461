import React, { useState, useEffect } from "react";
import { Button, Flex, Input, Text, useToast, AlertDialog, AlertDialogBody, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, AlertDialogCloseButton } from '@chakra-ui/react';
import { useAccount, useWriteContract, useWaitForTransactionReceipt } from 'wagmi';
import { parseEther } from 'ethers';
import abi from './Gnats.json';

function Mint() {
    const [mintAmount, setMintAmount] = useState(1);
    const toast = useToast();
    const { data: hash, writeContractAsync } = useWriteContract();
    const { isConnected } = useAccount();
    const [isProgressDialogOpen, setIsProgressDialogOpen] = useState(false);
    const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState(null);

    useEffect(() => {
        console.log('mintAmount updated: ' + mintAmount);
    }, [mintAmount]);

    const handleWriteContract = async () => {
        try {
            const txHash = await writeContractAsync({
                address: '0xC3cdc31392B062c79A1307dD489079b28D8404e7',
                abi,
                functionName: 'mint',
                args: [mintAmount],
                value: parseEther((0.08 * mintAmount).toFixed(18)),
            });

            setIsProgressDialogOpen(true);
            console.log('txHash: ' + txHash.toString());
        } catch (error) {
            setIsProgressDialogOpen(false);
            toast({
                position: 'top',
                title: "Error",
                description: `${error.shortMessage}`,
                status: "error",
                duration: 4000,
                isClosable: true,
            });
            console.error('Error writing contract:', error);
        }
    };

    const { isSuccess: isConfirmed, data: receipt } = useWaitForTransactionReceipt({ hash });

    useEffect(() => {
        if (isConfirmed && receipt) {
            setIsProgressDialogOpen(false);
            const tokenIds = receipt.logs.map(log => parseInt(log.topics[3], 16));
            const details = {
                names: tokenIds.map(id => `Gnats#${id}`).join(', '),
                mintAmount: (0.00002 * mintAmount).toFixed(4),
                transactionHash: hash,
                timestamp: new Date().toLocaleString(),
                etherscanUrl: `https://etherscan.io/tx/${hash}`,
                numberOfNFTs: mintAmount,
            };
            setTransactionDetails(details);
            setIsSuccessDialogOpen(true);
        }
    }, [isConfirmed, toast, hash, receipt, mintAmount]);

    const handleCopyDetails = () => {
        if (transactionDetails) {
            const detailsString = `
                Name: ${transactionDetails.names}
                Number of NFTs Minted: ${transactionDetails.numberOfNFTs}
                Timestamp: ${transactionDetails.timestamp}
                Etherscan URL: ${transactionDetails.etherscanUrl}
                Info: You can view your NFT on OpenSea now by connecting your wallet.
            `;
            navigator.clipboard.writeText(detailsString);
            toast({
                position: 'top',
                title: 'Copied',
                description: 'Transaction details copied to clipboard',
                status: 'success',
                duration: 2000,
                isClosable: true,
            });
        }
    };

    const HandleDecrement = () => {
        if (mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);
    };

    const HandleIncrement = () => {
        if (mintAmount >= 10) return;
        setMintAmount(mintAmount + 1);
    };

    return (
        <div>
            {
                (isConnected) ? (
                    <div>
                        <Flex align="center" justify="center" paddingBottom="10px">
                            <Button
                                backgroundColor="red"
                                borderRadius="5px"
                                boxShadow="0px 2px 2px 1px #F0F0F"
                                color="white"
                                cursor="pointer"
                                fontFamily="inherit"
                                height="10px"
                                padding="15px"
                                margin="0 15px"
                                onClick={HandleDecrement}> -
                            </Button>
                            <Input
                                readOnly
                                fontFamily="inherit"
                                fontSize="small"
                                color="black"
                                backgroundColor="white"
                                width="100px"
                                height="30px"
                                textAlign="center"
                                paddingLeft="19px"
                                type="number"
                                value={mintAmount} />
                            <Button
                                backgroundColor="green"
                                borderRadius="5px"
                                color="white"
                                cursor="pointer"
                                fontFamily="inherit"
                                padding="15px"
                                height="10px"
                                margin="0 15px"
                                onClick={HandleIncrement}> +
                            </Button>
                        </Flex>
                        <Flex align="center" justify="center">
                            <Button
                                backgroundColor="orange"
                                borderRadius="5px"
                                boxShadow="0px 2px 2px 1px #F0F0F"
                                color="black"
                                cursor="pointer"
                                height="10px"
                                fontFamily="Arial, Helvetica, sans-serif;"
                                fontSize="14px"
                                padding="15px"
                                margin="0 15px"
                                onClick={handleWriteContract}> Mint Now
                            </Button>
                        </Flex>
                    </div>
                ) : (
                    <Text
                        marginTop="10px"
                        fontSize="18px"
                        fontFamily="Arial, Helvetica, sans-serif;"
                        color="goldenrod"
                        fontWeight="bold"
                    >Release Date will be Announced Soon
                    </Text>
                )
            }

            {/* Progress Dialog */}
            <AlertDialog
                isOpen={isProgressDialogOpen}
                onClose={() => {}}
                isCentered
                leastDestructiveRef={undefined}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent bg="goldenrod" borderRadius="lg" maxWidth="400px">
                        <AlertDialogHeader fontSize="large" fontWeight="bold">
                            Gnats Minting
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Text fontFamily='Ariel' fontWeight='bold'> Your minting transaction is in progress...</Text>
                        </AlertDialogBody>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {/* Success Dialog */}
            <AlertDialog
                isOpen={isSuccessDialogOpen}
                onClose={() => setIsSuccessDialogOpen(false)}
                isCentered
                leastDestructiveRef={undefined}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent bg="goldenrod" borderRadius="lg" maxWidth="1000px">
                        <AlertDialogHeader fontSize="lg" fontWeight="bold" textDecor='ButtonFace'>
                            Mint Successful
                        </AlertDialogHeader>
                        <AlertDialogCloseButton />
                        <AlertDialogBody>
                            <Text><strong>Name:</strong> {transactionDetails?.names}</Text>
                            <Text><strong>NFTs Minted:</strong> {transactionDetails?.numberOfNFTs}</Text>
                            <Text><strong>Timestamp:</strong> {transactionDetails?.timestamp}</Text>
                            <Text>
                                <strong>Etherscan URL:</strong>{" "}
                                <a href={transactionDetails?.etherscanUrl} target="_blank" rel="noopener noreferrer">
                                    {transactionDetails?.etherscanUrl}
                                </a>
                            </Text>
                            <Text><strong>View Gnats:</strong> You can view your Gnats NFT on <a href="https://opensea.io/" target="_blank" rel="noopener noreferrer">OpenSea</a> now by connecting your wallet.</Text>
                            <Button onClick={handleCopyDetails} mt={4} colorScheme="blue">Copy Details</Button>
                        </AlertDialogBody>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </div>
    );
}

export default Mint;
