import React from 'react';
import { Text } from "@chakra-ui/react";
import logo from "./assets/gnatslogo.png";
import Mint from './Mint';
import './maintext.css';

const MainText = () => {
    return (  
        <div className="maintext-container">
            <div className="maintext-logo-box">
                <img src={logo} alt="Gnats" className="logo-img" />
                <div className="maintext-writeup-text">
                    <Text as="p">In the dark night they are roaming in empty space, mesmerising your brain forever</Text>
                    <Text as="p">Their randomness, colours would keep you addicted. Created out of nothingness,</Text>
                    <Text as="p">Once you look at it we promise you will not blink your eyes. it's lovely!</Text>
                </div>
                <div className='mint'>
                     <Mint />
                </div>
            </div>
            <div className="maintext-gif"></div>
        </div>
    );
};

export default MainText;
