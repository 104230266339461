import { ChakraProvider, Image, Circle } from '@chakra-ui/react';
import buttonhome from './assets/buttonhome3.png';
import Footer from './footer';
import { NavLink } from 'react-router-dom';
import Gnats1 from './assets/1.gif';
import Gnats2 from './assets/2.gif';
import Gnats3 from './assets/3.gif';
import Gnats4 from './assets/4.gif';
import './about.css';
import React, { useEffect } from 'react';
import ReactGA from 'react-ga4'; // Import ReactGA
export default function About(){

 // Track the pageview on component mount
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/about", title: "About Page" });
    }, []);


    return(
        <div> 
            <ChakraProvider>
            <section className="section-about">
				<div className="box-main-about">
                    <div>
                        <Circle size='700px' bg='black'><Image src={Gnats1} boxSize='495px'></Image></Circle>
                    </div>
					<div className="secondhalf-about">
                        <div className='homebutton-about'><NavLink to='/'><Image src={buttonhome}></Image></NavLink></div>
						<h1 className="text-big-about">
							About Gnats
						</h1>                    
						<p className="text-small-about">Dear Gnats community, holders and fellow enthusiasts,</p>
						<p className="text-small-about">
                        Beauty of Gnats lie in it's motion, random & unique number of Gnats in each artwork, randomness of colors, random flight path, randomly created natural shapes, random colors in shapes, Gnats are short lived, they can enter and exit shapes. All these characteristics make them unique. There are 10K of such Gnats NFT's and Each NFT is different from each other with reference to all these mentioned traits. Gnats are short lived meaning they come in to existence out of nothingness, like subatomic particles being created out of thin air. It took long time to carve this project to shape and we are exited to present to the community.        
                        </p>
                       
					</div>
				</div>
			</section>
            
            <section className='section-about'>
                <div className='box-main-about'>
                    <div className='secondhalf-about'> 
                        <p className='text-big-about'> Simplistic.</p>
                        <p className='text-small-about'>
                            Design and approch is very simple, in each artwork you will see flying Gnats with natural random shapes, that's it! but still they are very different and no two artworks are same. These intricate characteristics are inherent in the design. A good description is randomness in the NFTs as those are the inherent characteristics of each one. it's a very unique project which springs the feeling of creativity, positivity and love at first sight.                            
                        </p>
                    </div>
                    <div>
                    <Circle size='700px' bg='black'><Image src={Gnats2} boxSize='495px'></Image></Circle>
                    </div>
                </div>
            </section>

            <section className="section-about">
				<div className="box-main-about">
                    <div>
                        <Circle size='700px' bg='black'><Image src={Gnats3} boxSize='495px'></Image></Circle>
                    </div>
					<div className="secondhalf-about">
						<h1 className="text-big-about">
                        Gnats Flight Path
						</h1>
						<p className="text-small-about">
                        Gnats has been created unique in every sense even to the extent that flight path each gnat will take would be unique and this is calculated by cumulating flight path of all the gnats in the artwork and generating a single value. So Flight Path is represented in Pixels. "TotalFlightPath" is the distance in Pixels all gnats covered, this distance is uniques in all 10K NFT's. This is very exciting!                    
                        </p>
					</div>
				</div>
			</section>

            <section className='section-about'>
                <div className='box-main-about'>
                    <div className='secondhalf-about'> 
                        <p className='text-big-about'> Disclaimer </p>
                        <p className='text-small-about'>
                        This is to inform all users that the GNATS project has no association with any previous individuals, organizations, or entities that may have been involved with similar projects or initiatives. All content, materials, and work associated with this GNATS project are independently developed and maintained by the current team. Any similarities to other projects are purely coincidental.
                            </p>
                    </div>
                    <div>
                        <Circle size='700px' bg='black'><Image src={Gnats4} boxSize='495px'></Image></Circle>
                    </div>
                </div>
            </section>
            <Footer></Footer>
            </ChakraProvider>
        </div>
    );
}
