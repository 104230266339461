import { Accordion, AccordionItem, AccordionButton, Box, AccordionIcon, AccordionPanel, Image } from "@chakra-ui/react";
import { NavLink } from 'react-router-dom';
import buttonhome from './assets/buttonhome3.png';
import Footer from "./footer";
import './faq.css';

export default function faq(){
    return(
        <div>

            <div>
                <div className="homebutton-top"><NavLink to='/'><Image src={buttonhome}></Image></NavLink></div>
                <div className="faq-heading"><h1>FAQ</h1></div>
            </div>
            
            <Accordion allowMultiple>
                <AccordionItem className="qa-faq">
                    <h2>
                    <AccordionButton className="accordian">
                        <Box className="text-big-faq" as="span" flex='1' textAlign='left'>
                        Q1: What are Gnats?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel className="text-small-faq" pb={4}>
                    A1: Gnats are a collection of unique, animated NFTs representing randomly created colourful flying creatures. Each Gnat has its own distinct color, flight path and natural shapes. Each GNAT artwork has unique number of Gnats which are randomly generated, each Gnat has different colour, each Gnat has random flight path and each Gnat has randomly created different shapes making it a truly one-of-a-kind digital artwork.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem className="qa-faq">
                    <h2>
                    <AccordionButton className="accordian">
                        <Box className="text-big-faq" as="span" flex='1' textAlign='left'>
                        Q2: How many Gnats are there in total?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel className="text-small-faq" pb={4}>
                    A2: The Gnats NFT collection consists of a total of 10,000 unique Gnats. No two Gnats are the same, ensuring the rarity and individuality of each NFT.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem className="qa-faq">
                    <h2>
                    <AccordionButton className="accordian">
                        <Box className="text-big-faq" as="span" flex='1' textAlign='left'>
                        Q3: How can I mint or acquire a Gnat?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel className="text-small-faq" pb={4}>
                    A3: To mint a Gnat, visit our official opensea page and follow the instructions provided. Each Gnat can be purchased at a set price during the minting phase. Make sure to stay updated through our official channels for announcements regarding minting windows and availability.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem className="qa-faq">
                    <h2>
                    <AccordionButton className="accordian">
                        <Box className="text-big-faq" as="span" flex='1' textAlign='left'>
                        Q4: Can I trade or sell my Gnats?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel className="text-small-faq" pb={4}>
                    A4: Yes, as an NFT holder, you have full ownership rights, including the ability to trade or sell your Gnats on compatible NFT marketplaces. The value of your Gnats may vary depending on factors such as demand, rarity, and market trends.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem className="qa-faq">
                    <h2>
                    <AccordionButton className="accordian">
                        <Box className="text-big-faq" as="span" flex='1' textAlign='left'>
                        Q5: Will there be any future variations or editions of Gnats?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel className="text-small-faq" pb={4}>
                    A5: Yes, our roadmap includes plans for introducing further variations and editions of Gnats. These additions may offer new features, traits, or collaborations with renowned artists, expanding the collection and providing collectors with more options to choose from.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem className="qa-faq">
                    <h2>
                    <AccordionButton className="accordian">
                        <Box className="text-big-faq" as="span" flex='1' textAlign='left'>
                        Q6: How can I stay connected with the Gnats NFT community?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel className="text-small-faq" pb={4}>
                    A6: We encourage you to join our official Discord server, follow our social media accounts and community events. Engage with fellow collectors, participate in discussions, and share your Gnats journey.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem className="qa-faq">
                    <h2>
                    <AccordionButton className="accordian">
                        <Box className="text-big-faq" as="span" flex='1' textAlign='left'>
                        Q7: Are there any benefits or incentives for long-term Gnats holders?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    </h2>
                    <AccordionPanel className="text-small-faq" pb={4}>
                    A7: Yes, we value our long-term Gnats holders. We may periodically provide rewards, airdrops, or exclusive perks to show our appreciation for your continued support. Stay tuned for announcements and updates regarding such incentives.
                    </AccordionPanel>
                </AccordionItem>

            </Accordion>
            <div className="gnatsgif_faq"></div>
            <div className="emptyspace_faq" />
            <Footer></Footer>
        </div>
    )
}

