import { Link, Image } from '@chakra-ui/react';
import Twitter from "./assets/x1.svg";
import './footer.css';

export default function Footer(){
    return(
        <footer className="footer">
            <div className='social-links'> 
                <Link target='_blank'  href="https://x.com/Gnatslive">
                    <Image src={Twitter} boxsize="100px" margin="0 15px" />
                </Link>                   
            </div>
            <p className="text-footer">
                Copyright Gnats 2024 ©-All rights are reserved
            </p>
        </footer>
    )
}
